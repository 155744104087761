@use 'th-common/styles/index';

$dialog-padding-left: index.$padding-xs;
$dialog-padding-right: index.$padding-md;

$dialog-content-padding: index.$padding-xxs;

.surfaceBox {
  color: var(--color-on-surf-variant);

  &_fullHeight {
    height: 100%;
  }

  &_scrollable {
    overflow: auto;
  }

  &_surf {
    color: var(--color-on-surf);
    background-color: var(--color-surf);
  }

  &_surfContainer {
    background-color: var(--color-surf-container);
  }

  &_surfContainerHigh {
    background-color: var(--color-surf-container-high);
  }

  &_surfContainerHighest {
    background-color: var(--color-surf-container-highest);
  }

  &_surfaceContainerHighReverse {
    --th-table-background: var(--color-surf-container-high);

    margin: 0 -#{index.$padding-xxs};
    padding: index.$padding-sm;
    background-color: var(--color-surf-container-high);
    border-radius: index.$radius-md;
  }
}
